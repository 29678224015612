import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const FooterWrapper = styled.footer`
	display: flex;
	margin-top: auto;
	padding: 20px;
	flex-wrap: wrap;
	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
	@media (min-width: 1024px) {
		padding: 50px;
	}
`;

const FooterSection = styled.section`
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 25px;

	@media (min-width: 1024px) {
		width: 50%;
		padding-right: 50px;
		margin-bottom: 50px;

		&:nth-of-type(2) {
			padding-left: 10px;
		}
	}
`;

const FooterSectionText = styled.p`
	font-size: 1.3333333333rem;
	max-width: 25ch;
	font-family: 'GrotexRegular';
	font-size: 1.3333333333rem;
	line-height: 1.6666666667rem;

	@media (min-width: 1024px) {
		font-size: 1.4222222222rem;
		line-height: 1.8666666667rem;
		max-width: 40ch;
	}
`;

const FooterNavWrapper = styled.section`
	display: flex;
	width: 100%;
	align-items: flex-end;
	flex-wrap: wrap;
	font-family: 'GrotexLight';

	@media (min-width: 1024px) {
		flex-direction: row;
		align-items: baseline;
	}
`;

const FooterCredits = styled.small`
	width: 100%;
	order: 3;
	margin-top: 20px;

	@media (min-width: 1024px) {
		width: 50%;
		order: 1;
		margin: 0;
		font-size: 1.0666666667rem;
		line-height: 1.3333333333rem;
	}
`;

const FooterNav = styled.ul`
	width: 50%;
	box-sizing: border-box;
	line-height: 1.3333333333rem;

	@media (min-width: 1024px) {
		width: 25%;
		order: 2;
		padding: 0 10px;
		font-size: 1.0666666667rem;
		line-height: 1.3333333333rem;
	}
`;

const AboutFooter = styled.footer`
	padding: 20px;
	font-family: 'GrotexLight';
	@media (min-width: 1024px) {
		padding: 50px;
		span {
			font-size: 1.0666666667rem;
		}
	}
`;

const Footer = (props) => (
	<>
		{props.aboutPage ? (
			<AboutFooter>
				<span>© {new Date().getFullYear()} Bullpen</span>
			</AboutFooter>
		) : (
			<FooterWrapper>
				<FooterSection>
					<FooterSectionText>Bullpen Studio</FooterSectionText>
					<FooterSectionText>
						Making engaging content through design & animation
					</FooterSectionText>
					<FooterSectionText>NYC</FooterSectionText>
				</FooterSection>

				<FooterSection>
					<Link to={'/'}>
						<FooterSectionText>Work</FooterSectionText>
					</Link>
					<Link to={'/experiments'}>
						<FooterSectionText>Experiments</FooterSectionText>
					</Link>
					<Link to={'/info'}>
						<FooterSectionText>Info</FooterSectionText>
					</Link>
				</FooterSection>

				<FooterNavWrapper>
					<FooterCredits>
						© {new Date().getFullYear()} Bullpen Studio
					</FooterCredits>
					<FooterNav>
						{props.data.email && (
							<li>
								<a href={`mailto:${props.data.email}`}>{props.data.email}</a>
							</li>
						)}
						{props.data.instagram && (
							<li>
								<a
									href={`${props.data.instagram}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									Instagram
								</a>
							</li>
						)}
					</FooterNav>
					<FooterNav>
						{props.data.vimeo && (
							<li>
								<a
									href={`${props.data.vimeo}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									Vimeo
								</a>
							</li>
						)}
						{props.data.behance && (
							<li>
								<a
									href={`${props.data.behance}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									Behance
								</a>
							</li>
						)}
					</FooterNav>
				</FooterNavWrapper>
			</FooterWrapper>
		)}
	</>
);

export default Footer;
