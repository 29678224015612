import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Lottie from 'react-lottie';
// import Logo from '../../images/logo.svg';
// import { ReactSVG } from 'react-svg';
import Menu from '../../images/Menu_Build.json';
import MobileMenu from '../MobileMenu';

const HeaderWrapper = styled.header`
	position: sticky;
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	padding: 20px;
	box-sizing: border-box;
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
	z-index: 9;
	background-color: white;

	a {
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}

	@media (min-width: 1024px) {
		position: ${(props) => (props.experimentPage ? 'fixed' : 'sticky')};
		padding: 50px;
		max-width: ${(props) => props.experimentPage && '1720px'};
		align-items: center;
	}
`;

const LogoImg = styled.div`
	svg {
		max-height: 32px;
		max-width: 32px;
		width: 100%;
	}
	z-index: 999;
	vertical-align: middle;

	@media (min-width: 1024px) {
		svg {
			max-height: 50px;
			max-width: 50px;
		}
	}
`;

const MobileMenuButton = styled.div`
	@media (min-width: 1024px) {
		display: none;
	}
`;

const Nav = styled.nav`
	display: none;
	font-family: 'GrotexRegular';

	@media (min-width: 1024px) {
		font-size: 1.4222222222rem;
		line-height: 1.8666666667rem;
		display: flex;
		justify-content: flex-end;

		a {
			margin-left: 20px;
			padding-top: 0px;

			&:hover {
				text-decoration: underline;
			}

			&:first-of-type {
				margin: 0;
			}
		}
	}
`;

const Header = (props) => {
	const [navOpen, setNavOpen] = useState(false);

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: Menu,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	const openMenu = () => {
		setNavOpen(true);
	};

	const closeMenu = () => {
		setNavOpen(false);
	};

	return (
		<HeaderWrapper experimentPage={props.experimentPage}>
			<Link to={'/'}>
				<LogoImg>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.96 58.96">
						<g id="Layer_2" data-name="Layer 2">
							<g id="expanded">
								<path d="M37.92,31.11H16.3V12.72H37.92a9.2,9.2,0,0,1,0,18.39ZM19.1,28.31H37.92a6.4,6.4,0,0,0,0-12.79H19.1Z" />
								<path d="M31.54,38.9H24.27V20.52h7.27a9.19,9.19,0,1,1,0,18.38Zm-4.47-2.8h4.47a6.39,6.39,0,1,0,0-12.78H27.07Z" />
								<path d="M37.92,46.69H16.3V28.31H37.92a9.19,9.19,0,0,1,0,18.38ZM19.1,43.89H37.92a6.39,6.39,0,0,0,0-12.78H19.1Z" />
								<rect x="24.27" y="21.92" width="2.8" height="23.38" />
								<rect x="10.32" y="12.72" width="13.16" height="2.8" />
								<rect x="10.32" y="43.89" width="13.16" height="2.8" />
								<rect x="17.77" y="20.52" width="9.7" height="2.8" />
								<path d="M43,59H15.92A15.94,15.94,0,0,1,0,43V15.92A15.94,15.94,0,0,1,15.92,0H43A15.94,15.94,0,0,1,59,15.92V43A16,16,0,0,1,43,59ZM15.92,2.8A13.14,13.14,0,0,0,2.8,15.92V43A13.14,13.14,0,0,0,15.92,56.16H43A13.14,13.14,0,0,0,56.16,43V15.92A13.14,13.14,0,0,0,43,2.8Z" />
							</g>
						</g>
					</svg>
				</LogoImg>
			</Link>
			{isTabletOrMobile ? (
				<MobileMenuButton onClick={openMenu}>
					<Lottie options={defaultOptions} height={36} width={36} />
				</MobileMenuButton>
			) : (
				<Nav>
					<Link to={'/'} activeStyle={{ textDecoration: 'underline' }}>
						Work
					</Link>
					<Link
						to={'/experiments'}
						activeStyle={{ textDecoration: 'underline' }}
					>
						Experiments
					</Link>
					<Link to={'/info'} activeStyle={{ textDecoration: 'underline' }}>
						Info
					</Link>
				</Nav>
			)}
			<MobileMenu className={navOpen ? 'open' : 'closed'} onClick={closeMenu} />
		</HeaderWrapper>
	);
};

export default Header;
