import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Lottie from 'react-lottie';
import Close from '../images/X_Build.json';
import Logo from '../images/logo.svg';

const MobileMenu = styled.nav`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: white;
	padding-top: 100px;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.21, 0, 0.28, 0.8);

	a {
		display: block;
		padding-left: 20px;
		font-size: 1.4222222222rem;
		line-height: 1.8666666667rem;
	}

	&.open {
		pointer-events: all;
		opacity: 1;
	}
`

const LogoImg = styled.img`
	max-height: 32px;
	max-width: 32px;
	width: 100%;
	z-index: 999;
	position: absolute;
	top: 20px;
	left: 20px;
`;

const Button = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 0;
	border: 0;
	background-color: transparent;
	border-radius: 0;
`;

const MobileMenuComponent = ({ className, onClick }) => {
	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: Close,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<MobileMenu className={className}>
			<Link to={'/'}>
				<LogoImg src={Logo} />
			</Link>
			<Button onClick={onClick}>
				<Lottie options={defaultOptions} height={36} width={36} />
			</Button>
			<Link to={'/'}>Work</Link>
			<Link to={'/experiments'}>Experiments</Link>
			<Link to={'/info'}>Info</Link>
		</MobileMenu>
	);
};
export default MobileMenuComponent;
