import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import 'reset-css';
import './Layout.scss';

import { SwitchTransition, Transition } from 'react-transition-group'
import { useLocation } from '@reach/router'

export const TRANSITION_DURATION = 400

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
}

const Layout = ({ children, experimentPage, aboutPage }) => {
	const location = useLocation()

	const data = useStaticQuery(graphql`
		{
			allSanitySiteSettings {
				nodes {
					title
				}
			}
			sanityContact {
				email
				instagram
				behance
				twitter
				vimeo
			}
		}
	`)

	return (
		<React.Fragment>
			<Header title={data.allSanitySiteSettings.nodes[0].title} experimentPage={experimentPage} />
			<SwitchTransition>
				<Transition key={location.pathname} mountOnEnter unmountOnExit appear timeout={TRANSITION_DURATION}>
					{status => (
						<main
							id='maincontent'
							style={{
								...TRANSITION_STYLES.default,
								...TRANSITION_STYLES[status],
							}}>
							{children}
							{!experimentPage && <Footer data={data.sanityContact} aboutPage={aboutPage} />}
						</main>
					)}
				</Transition>
			</SwitchTransition>
		</React.Fragment>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
